<template>
  <b-card>
    <b-row>
      <b-col>
        Dados
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: 'Dados',
}
</script>

<style scoped>

</style>
